
  .scan-area {
    background-color: #282c34bb;
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    position: fixed;
    top: 0;
    left: 0;
    font-size: calc(10px + 2vmin);
    color: white;
    display: grid;
    place-content: center;

  }

  .qr-area{
    width: 600px;
    max-width: 100vw;
  }

  #html5-qrcode-select-camera{
    display: block;
    margin: 20px auto;
    --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
    display: block;
    width: max-content;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  #html5-qrcode-select-camera:disabled {
    display: none;
}
  #html5-qrcode-button-camera-start,#html5-qrcode-button-camera-stop,#html5-qrcode-button-camera-permission{
    background-color: #4CBCD2;
    display: inline-block;
    font-weight: 400;
    color: white;
    min-width: 6rem;
    height: 3rem;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid white;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.5rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
/*   
  .QRScanner {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
  }
   */